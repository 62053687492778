import React from 'react';
import './Header.css';
import Logo from './../../assets/logo.png';

function Header() {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
    
        <div className="header">
            <div className="lo">
            <img src={Logo} alt="" className='logo' />
            </div>
            <ul className='header-menu'>
                <li onClick={() => scrollToSection('home')}>Home</li>
                <li onClick={() => scrollToSection('about-us')}>About us</li>
                <li onClick={() => scrollToSection('services')}>Services</li>
                <li onClick={() => scrollToSection('testimonials')}>Testimonials</li>
            </ul>
        </div>
    );
}

export default Header;
